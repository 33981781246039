/* Start style of Shop */
body.pushable .pusher{
    background: #f0f0f0;
}
#menu{
    height: 110px;
    background: #fff;
    //background: none;
    border-bottom: solid 1px #eee;
    -webkit-box-shadow: 0 0 10px 1px #888;
    -moz-box-shadow: 0 0 10px 1px #888;
    box-shadow: 0 0 10px 1px #888;
    padding:30px 0;
    z-index: 2;
    margin-bottom: 30px;
}
//#menu.with-top-image{
//    background: none;
//    border-bottom: none;
//    -webkit-box-shadow: none;
//    -moz-box-shadow: none;
//    box-shadow: none;
//}
.main-menu.ui.menu .menu .item > i.icon{
    margin: 0;
}
.top-image{
    width: 100%;
    height: 500px;
    margin-top: -30px;
}
#logo{
    display: inline-block;
    margin-top: -13px;
}
#sylius-cart-button{
    margin-top: 4px;
}
.no-gutter.grid {
    margin: 0 !important;
}
.no-gutter.grid .column {
    padding: 0 !important;
}
.mini-gutter.grid {
    margin: 3px !important;
}
.mini-gutter.grid .column {
    padding: 3px !important;
}
.ui.secondary.segment,
.ui.placeholder.segment{
    background: #FFF;
}
/* End style of Shop */
